//react libraries import
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

//custom libraries import


const AboutUsPage = () => {
    return(
        <main>
            <div className="my-8 px-4 md:px-24">
                <p className="text-center text-2xl md:text-3xl">About <span className="text-third font-bold">Shree Mahendra Secondary School</span></p>
                <div className="my-4">
                    <StaticImage
                        src="../images/about.jpg"
                        width={600}
                        quality={100}
                        className="my-2 w-80 h-88 md:w-88 md:h-96 md:float-left md:mr-8"
                        formats={["auto", "webp", "avif"]}
                        alt="School Logo"
                    />
                    <p className="mt-6">
                        Shree Mahendra Secondary School established in 2016 B.S. is a well established, reputed, co-educational school situated at Lahachok, Machhapuchchhre - 4, Kaski serving local communities. The school is affiliated to <b>Secondary Education Examination (SEE) Board</b>.
                    </p>
                    <p className="mt-6">
                        We serve the educational need of hundreds of students from Nursery through X. The classes in Shree Mahendra Secondary School for every academic year starts from Baisakh to Chaitra.
                    </p>
                    <p className="mt-6">
                        We pride ourselves on our warmth and welcoming nature; open attitude towards students, teachers and parents; safe & secure environment; well trained teachers; modern infrastructure; small class strengths; low student – teacher ratio and updated curriculum.
                    </p>
                    <p className="mt-6">
                        Shree Mahendra Secondary School believes that the primary purpose of a school is to guide the child in the discovery of themselves and their world…. to identify and nurture their talents. Just as every seed contains the future tree, each child is born with infinite potential. We see children as seeds to be nurtured – here the teacher is a gardener who helps to bring out the potential already present in the child. We provide the best possible environment for children to grow, to blossom and to evolve.
                    </p>
                </div>
                <div className="mt-16">
                    <p className="text-center text-xl font-semibold text-primary uppercase"> Academic Programs</p>
                    <div className="mt-6">
                        <p className="text-left text-base font-semibold">School Level & Classes</p>
                        <ul className="w-1/2 mt-4">
                            <li >
                                Secondary School (IX-X)
                            </li>
                            <li>
                                Middle School (VI-VIII)
                            </li>
                            <li>
                                Primary School (I-V)
                            </li>
                            <li>
                                Pre Primary School (Nursery)
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="my-16">
                    <p className="text-center text-xl font-semibold text-primary uppercase">Awards & Recognitions</p>
                    <div className="mt-8 w-1/2 text-sm md:text-xl bg-extra-light-primary text-blue py-8 px-4 md:px-6 md:py-6 rounded-md">
                        Presidential Cup 400 m <span className="ml-2">2076 B.S.</span> <span className="italic uppercase text-third float-right">Winner</span>
                    </div>
                    <div className="mt-6 w-1/2 text-sm md:text-xl bg-extra-light-primary text-blue py-8 px-4 md:px-6 md:py-6 rounded-md">
                        District Level Football <span className="ml-6">2075 B.S.</span> <span className="italic uppercase text-third float-right">Winner</span>
                    </div>
                </div>
            </div>
        </main>
    );
}


export default AboutUsPage;